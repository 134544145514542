import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';

const Title = ({
   title,
   subtitle,
   excludeBranding,
   description = 'Learn mixing, mastering, sound design, and more! Ear Instructor offers ear training exercises and interactive tutorials for producers and audio engineers.',
}) => {
   const fullTitle = useMemo(() => {
      const fragments = [];

      if (title) {
         fragments.push(title);
      }
      if (subtitle) {
         fragments.push(subtitle);
      }
      if (!excludeBranding) {
         fragments.push('Ear Instructor');
      }

      return fragments.join(' - ');
   }, [title, subtitle, excludeBranding]);

   useEffect(() => {
      document
         .getElementById('site-meta-description')
         .setAttribute('content', description);
   }, [description]);

   return (
      <Helmet>
         <title>{fullTitle}</title>
      </Helmet>
   );
};

export { Title };
