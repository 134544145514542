/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { HorizontalLogo } from './branding/HorizontalLogo';
import { getNoteName } from './util/getNoteName';
import { Title } from './components/Title';

const HomeScreen = () => {
   // const [hasJoined] = useState(localStorage.getItem('eiauth-hasJoined'));

   const [hasJoined] = useState(localStorage.getItem('eipublicea-hasJoined'));
   const [noteArray] = useState(new Array(25).fill(null));

   if (hasJoined) {
      return <Navigate to="/app/dashboard" replace />;
   }

   return (
      <div className="landing-page">
         <Title
            title="Ear Instructor"
            subtitle="Ear Training for Audio Engineers and Producers"
            excludeBranding
         />
         <div className="landing-page-logo">
            <HorizontalLogo />
         </div>
         <div className="landing-page-wrapper">
            <div className="landing-page-content">
               <h1>
                  Learn Music <span>Through Practice</span>
               </h1>
               <p>
                  Combining ear training with practice is the best way to learn
                  music production, and now you don&apos;t have to figure it out
                  on your own. Ear Instructor provides fun exercises and
                  tutorials to help you level up your knowledge and skills.
               </p>
               <Link to="/getting-started" className="landing-page-button">
                  Practice Now
               </Link>
               <div className="landing-page-piano-overlay" />
            </div>
            <div className="landing-page-piano">
               {noteArray.map((val, i) => {
                  const note = i - 22;
                  const noteName = getNoteName(note);
                  return <div key={note} data-note-name={noteName} />;
               })}
            </div>
         </div>
      </div>
   );
};

export { HomeScreen };
