import React from 'react';
import { createRoot } from 'react-dom/client';
import { RootRouter } from './RootRouter';
import { VisitInfo } from './app/VisitInfo';

VisitInfo();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
   <React.StrictMode>
      <RootRouter />
   </React.StrictMode>
);
